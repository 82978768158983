import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoStream from "../components/img/LogoStream"

const TermsPage = () => {
    return <Layout>
        <Seo
            title={`Terms & Conditions`}
            description={``}
        />

        <LogoStream />

        <div className="content-wrapper">

            <div className="main">
                <h1>Gift Card Terms and Conditions</h1>

                <h2>General Terms</h2>
                <ul>
                    <li>Mitchells & Butlers Leisure Retail Ltd ("Dining Out Gift Card") supplies both physical gift cards ("Physical Gift Cards") and digital gift cards ("eGift Cards") to guests, together referred to herein as ("Gift Cards") which are issued by Mitchells & Butlers Leisure Retail Ltd, 27 Fleet St, Birmingham, B3 1JP (registered no 1001181), VAT Number - VAT Number and are supplied via the Gift Card agent, Blackhawk Network (Europe) Limited registered in England and Wales with its registered office at Westside, London Road, Hemel Hempstead, Hertfordshire, HP3 9TD England (registered number 07956446), who operates this website.   </li>

                    <li>If you have any complaints about our service or any  Gift Cards you purchase from us or wish to contact us please contact the Gift Card Guest Services on 0121 498 7098, opening hours are 8am to 8pm, 7 days a week, or email on guest.care@mbplc.com.</li>

                    <li>Gift Cards are redeemable as eGifts or Physical Gift Cards. With the exception of shipping fees for the delivery of Physical Gift Cards, no additional fees will be imposed on purchasers or recipients of a Gift Card.</li>

                    <li>Gift Cards cannot be refunded or exchanged for cash or credit. The Gift Card is not a cheque guarantee, credit, debit or charge card. </li>

                    <li>If we are unable to accept your order, the system will inform you of this and will not charge you. This might be because of a suspicious transaction alert identified on our system or because we do not deliver Gift Cards to your location.</li>

                    <li>Physical Gift Cards have no value until activated. Details of how to activate Physical Gift Cards will be included with each delivery.  eGift Cards will be activated at the point of ordering. eGift Cards are valid either presented on a smart phone or printed out.</li>
                </ul>

                <h2>Expiry of Gift Cards</h2>
                <p>Our Gift Cards are valid for valid for 24 months from the later of the date that any value was last loaded onto the Gift Card, or the date that any value on the Gift Card was last redeemed.</p>

                <h2>View Your Balance</h2>
                <p>Gift Cards are available in variable denominations, and you can choose any denomination between £5 - £250. No change will be given but Gift cards can be used multiple times until all of the remaining balance is spent. Card balances can be checked by clicking <a href="https://www.showmybalance.com" target="_blank" rel={`noreferrer`}>www.showmybalance.com</a>.  Alternatively, you can check your balance in any Mitchells & Butlers venue.</p>

                <h2>Your Rights To End The Contract</h2>
                <p>You have the right, in addition to your other rights, to cancel your order for Gift Cards, provided you are within the cooling off period of (i) in the case of eGift Cards fourteen (14) days after the date that we confirm that we accept your order or (ii) in the case of Physical Gift Cards (14) days after the day that they are delivered to you.</p>
                <p>If you choose to receive your eGift Cards before expiry of the fourteen (14) day cooling off period, you acknowledge and accept that you will lose your right to end the contract once downloading of the digital content begins.</p>

                <h2>Returns</h2>
                <p>Subject to the cooling off period you may not return or cancel your Gift Cards after they are received. If you wish to cancel your Gift Cards order prior to its delivery, please contact our Gift Card Guest Services on 0121 498 7098.</p>

                <h2>Lost or Stolen Gift Cards</h2>
                <p>Please keep your Gift Cards secure, as if they were cash. We are not responsible if the Gift Cards are lost, stolen, destroyed or used without permission. We reserve the right to cancel Gift Cards, if we reasonably suspect fraud or misconduct. Damaged, altered or cancelled Gift Cards will not be accepted.  </p>

                <h2>Limitations</h2>
                <p>The Gift Card is valid at any Mitchells & Butlers venue in the UK as detailed at www.thediningoutgiftcard.co.uk/ and subject to the conditions set out below. The Gift Card cannot be used (i) to make any online purchase (ii) at any Moto or other Mitchells & Butlers franchise or (iii) at any site not managed by Mitchells & Butlers: please check with your server that the Gift Card can be used at the particular site before ordering. Venues can be added and/or removed from the list above at any time at Mitchells & Butlers' discretion.</p>
                <p>Gift Cards cannot be resold, transferred for value, or redeemed for cash.</p>
                <p>Unused Gift Cards cannot be transferred or returned, and orders cannot exceed £5000 per day.</p>

                <h2>Privacy Policy</h2>
                <p>Please review our <a href="https://www.mbplc.com/privacy/" target="_blank">Privacy Policy</a> to understand more about how we use your data. For any further questions contact data.protection@mbplc.com.</p>

                <h2>Dining Out Gift Card Gift Card Guest Service</h2>
                <p>If you need assistance with any aspect of your purchase, or use of your Gift Cards, please contact Gift Card Guest Services on 0121 498 7098. You will be required to provide your order number.</p>

                <h2>Payment Methods</h2>
                <p>Gift Cards may be paid for with a valid Debit or Credit Card (we do not accept American Express).</p>

                <h2>Delivery Information</h2>
                <p>During the order process we will let you know when we will provide the Gift Cards to you. </p>
                <p>The costs of delivery for Physical Gift Cards will be as displayed to you on our website. eGift Cards will be delivered free of charge.</p>
                <p>Physical Gift Cards can be delivered to valid addresses within the United Kingdom with the exception of the Channel Islands.</p>
                <p>We are not responsible for Gift Cards that are not received due to your failure to enter an accurate physical address or email address for the recipient. If you have concerns about your order, please our Gift Card Guest Services on 0121 498 7098.</p>

                <h2>Reasons for Failed Delivery of eGift Cards</h2>
                <p>eGift Cards are delivered via email. If you have confirmed the recipient's email address but the eGift Cards have not been viewed within a reasonable period after the requested delivery date, the following is a list of the most common reasons why delivery may have failed:</p>
                <ul>
                    <li>Spam filter blocked email or routed it to a bulk/spam folder</li>
                    <li>Recipient's firewall blocked the email</li>
                    <li>Email inbox is over size limit</li>
                    <li>Invalid email address</li>
                </ul>
                <p>If a spam filter is blocking Gift Card emails from getting to an inbox, the email options will need to be modified so that Gift Card emails are not considered spam. </p>
                <p>If you need further assistance, contact our Gift Card Guest Services on 0121 498 7098 Please be ready to provide your order number.</p>

                <h2>Personalised Messaging </h2>
                <p>If you wish to add a personal message to your Dining Out Gift Card Gift Cards, simply type your message in the message field during the purchase process.</p>
                <p>Gift Cards personal messages are limited in length to the space provided on the eGift Cards, or to the space provided on the presentation carrier of Physical Gift Cards and there is no additional charge to include a personalised message.</p>
                <p>We reserve the right to cancel inappropriate, offensive or otherwise objectionable messages.</p>

                <h2>Updates to Terms and Conditions</h2>
                <p>We reserve the right to change these Terms and Conditions from time to time.</p>

                <h2>Governing Law</h2>
                <p>These terms are governed by English law and you can bring legal proceedings in respect of your Gift Cards in the English courts.</p>
            </div>
        </div>
    </Layout>
}

export default TermsPage
